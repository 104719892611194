<template>
  <p-icon
    :class="{ 'animate-scale': isCopied }"
    :icon="isCopied ? 'copied' : 'copy'"
    :size="size"
    :color="isCopied ? 'fg-accent' : 'fg-secondary'"
    @click="handleCopy"
  />
</template>

<script setup lang="ts">
const props = defineProps({
  copyText: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'md',
  },
})

const isCopied = ref(false)

const handleCopy = async () => {
  await navigator.clipboard.writeText(props.copyText)
  isCopied.value = true
  setTimeout(() => {
    isCopied.value = false
  }, 1000)
}
</script>
